import React from "react";

import { Flex } from "../style/Grid";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { Label } from "../style/Label";
import { BubbleNewsletterStyle } from "../style/Bubble/BubbleNewsletterStyle";
import { Heading } from "../style/Heading";
import Input from "./Input";
import Button from "./Button";
import { Checkbox } from "../style/Checkbox/Checkbox";
import { Link as GatsbyLink } from "gatsby";
import getStyledLink from "../style/Link/getStyledLink";

const Link = getStyledLink(GatsbyLink);

const BubbleNewsletter = (props) => (
  <Flex
    flexDirection={
      props.left
        ? ["column-reverse", null, null, "row", null]
        : ["column-reverse", null, null, "row-reverse", null]
    }
    justifyContent={"space-between"}
  >
    <Flex
      flexDirection={
        !props.left
          ? ["row", null, null, "column", null]
          : ["row-reverse", null, null, "column", null]
      }
      alignItems={
        !props.left
          ? ["flex-start", null, null, "flex-start", null]
          : ["flex-start", null, null, "flex-start", null]
      }
      justifyContent={
        !props.left
          ? ["flex-start", null, null, "flex-end", null]
          : ["flex-start", null, null, "flex-start", null]
      }
      width={["100%", null, null, "135px", null]}
      paddingBottom={[0, null, null, "45px", null]}
    >
      {props.personImage?.childImageSharp && (
        <Image
          image={props.personImage}
          width={["66px", null, null, "88px", null]}
          heigth={["66px", null, null, "88px", null]}
          mr={!props.left ? ["8px", null, null, "0", null] : 0}
          ml={!props.left ? 0 : ["8px", null, null, "0", null]}
          borderRadius="100%"
          mb={[0, null, null, "15px", null]}
          style={{ flexShrink: 0 }}
        />
      )}
      <Flex flexDirection="column" width="100%">
        <Text
          as="strong"
          color="white.100"
          fontWeight="bold"
          fontSize="2"
          lineHeight="1.4"
          mb="2px"
        >
          {props.name}
        </Text>
        <Text
          color="white.50"
          fontSize="1"
          lineHeight="19px"
          fontWeight="light"
          textAlign={"left"}
        >
          {props.position}
        </Text>
      </Flex>
    </Flex>
    <Flex
      width={["100%", null, null, "687px", null]}
      mb={["30px", null, null, "0", null]}
    >
      <BubbleNewsletterStyle left={props.left}>
        <Flex flexDirection="column" width={["100%", null, null, "100%", null]}>
          <Heading
            as="h3"
            fontSize={[3, 4, null, null, null]}
            color="EYYellow.default"
            fontWeight="light"
            lineHeight="2"
            mb="24px"
          >
            {props.description}
          </Heading>
          <form
            action="https://cestasey.us15.list-manage.com/subscribe/post?u=9105bba134410997a773a20fb&id=d8923ff7df"
            method="post"
            target="_blank"
            name="mc-embedded-subscribe-form"
            onSubmit={() =>
              window.dataLayer.push({
                event: "form.sent",
                form: "Přihlášení k Newsletteru",
              })
            }
          >
            <Flex flexDirection="column">
              <Label
                htmlFor="mce-EMAIL"
                width={["100%", null, null, null, null]}
              >
                E-mail
              </Label>
              <Flex
                justifyContent="stretch"
                flexWrap={["wrap", null, "nowrap", null, null]}
                mb="25px"
                flex
              >
                <Input
                  placeholder="jan.novak@seznam.cz"
                  name="EMAIL"
                  id="mce-EMAIL"
                  type="email"
                  required="true"
                />
                <Button
                  as="button"
                  type="submit"
                  value="Chci newsletter"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  width={["100%", null, "auto", null, null]}
                >
                  Odeslat
                </Button>
              </Flex>
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_9105bba134410997a773a20fb_d8923ff7df"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <Checkbox>
                Souhlasím se&nbsp;
                <Link
                  white
                  to={"/osobni-udaje#shromaovnpouvnzpstupovnosobnchdajmonosti"}
                  style={{ display: "contents" }}
                >
                  zpracováním osobních údajů.
                </Link>
                <input
                  type="checkbox"
                  name="group[5567][4]"
                  id="mce-group[5567]-5567-0"
                  value="4"
                  required
                />
                <span></span>
              </Checkbox>
            </Flex>
          </form>
        </Flex>
      </BubbleNewsletterStyle>
    </Flex>
  </Flex>
);

export default BubbleNewsletter;
