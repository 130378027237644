import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Flex } from "../Grid";

const BubbleNewsletterStyle = styled(Flex)`
  position: relative;
  border: 1px solid ${themeGet("colors.EYGrey01")};
  margin: 0;
  padding: 25px;
  width: 100%;
  justify-content: center;

  &:before, &:after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  form {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${themeGet("breakpoints.md")}) {
    padding: 64px 64px;
  }

  ${props =>
    !props.left &&
    `&:before {
    left: 75px;
    right: auto;
    bottom: -24px;
    border-width: 24px 51px 0 0;
    border-color: ${props.theme.colors.EYGrey01} transparent transparent transparent;
  }`}

  ${props =>
    !props.left &&
    `&:after {
    left: 76px;
    right: auto;
    bottom: -22px;
    border-width: 23px 50px 0 0;
    border-color: ${props.theme.colors.EYOffBlack.default} transparent transparent transparent;
  }`}

  ${props =>
    props.left &&
    `&:before {
    left: auto;
    right: 75px;
    bottom: -24px;
    border-width: 0 51px 24px 0;
    border-color: transparent ${props.theme.colors.EYGrey01} transparent transparent;
  }`}

  ${props =>
    props.left &&
    `&:after {
    left: auto;
    right: 76px;
    bottom: -22px;
    border-width: 0 50px 23px 0;
    border-color: transparent ${props.theme.colors.EYOffBlack.default} transparent transparent;
  }`}

  @media screen and (min-width: ${themeGet("breakpoints.lg")}) {
    //margin: ${props => (props.left ? "23px 0 0 40px" : "23px 40px 0 0")};
    padding: ${props =>
      props.left ? "64px 55px 64px 64px" : "64px 40px 64px 64px;"};

    justify-content: center;

    ${props =>
      props.left &&
      `&:before {
      left: -24px;
      right: auto;
      bottom: auto;
      top: 119px;
      border-width: 0 24px 51px 0;
      border-color: transparent ${props.theme.colors.EYGrey01} transparent transparent;
    }`}

    ${props =>
      props.left &&
      `&:after {
      left: -22px;
      right: auto;
      bottom: auto;
      top: 120px;
      border-width: 0 23px 50px 0;
      border-color: transparent ${props.theme.colors.EYOffBlack.default} transparent transparent;
    }`}

    ${props =>
      !props.left &&
      `&:before {
      left: auto;
      right: -24px;
      bottom: auto;
      top: 130px;
      border-width: 51px 24px 0 0;
      border-color: ${props.theme.colors.EYGrey01} transparent transparent transparent;
    }`}

    ${props =>
      !props.left &&
      `&:after {
      left: auto;
      right: -22px;
      bottom: auto;
      top: 131px;
      border-width: 50px 23px 0 0;
      border-color: ${props.theme.colors.EYOffBlack.default} transparent transparent transparent;
    }`}
  }
`;

BubbleNewsletterStyle.defaultProps = {};

export { BubbleNewsletterStyle };
