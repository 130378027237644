import styled, { css } from "styled-components";
import AmbassadorCardComponent from "../../components/AmbassadorCard";
import { space, layout, border, position, color } from "styled-system";
import propTypes from "@styled-system/prop-types";

const AmbassadorCard = styled(AmbassadorCardComponent)`
  border: 1px solid #2e2e38;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 0;
  flex-shrink: 0;
  ${space}
  ${layout}
  ${border}
  ${position}
  ${color}

  .personImage {
    width: 88px;
    height: 88px;
    border-radius: 100%;
    flex-shrink: 0;
  }

  .socialsButtonWrapper {
    & > * {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .link {
    position: relative;
    z-index: 1;
    transition: color 0.3s;

    & ~ * {
      transition: background-color 0.3s;
    }

    .arrowDark,
    .arrowYellow {
      transition: opacity 0.3s;
    }

    &:hover {
      text-decoration: none;
      color: #ffe600;

      & ~ * {
        background-color: #2e2e38;
      }

      .arrowDark {
        opacity: 0;
      }

      .arrowYellow {
        opacity: 1;
      }
    }
  }
`;

AmbassadorCard.propTypes = {
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.color,
};

export { AmbassadorCard };
