import React from "react";

import { Input as InputStyle } from "../../style/Input";

const Input = ({ placeholder, name, id, type, children, required }) => (
  <InputStyle
    type={type}
    placeholder={placeholder}
    name={name}
    id={id}
    required={required}
  >
    {children}
  </InputStyle>
);

export default Input;
