import React, { useLayoutEffect, useState, useEffect } from "react";

import Header from "../Header";
import TriangleBlogPost from "../../style/Header/triangleBlogPost";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Wrapper } from "../../style/Grid";
import { Article } from "../../style/Article";
import { ShareSocial } from "../ShareSocial";
import Container from "../../style/Grid/Container";
import { NavBar as NavBarStyle } from "../../style/NavBar";
import { Flex } from "../../style/Grid";
import { AmbassadorCard } from "../../style/AmbassadorCard";

const BlogPostContent = ({
  post: {
    frontmatter: {
      title,
      description,
      ambasadorTitle,
      ambasadorDescription,
      ambasadorName,
    },
  },
  headerPostImage,
  location,
  ContentComponent,
  ambassadors,
  otherPosts,
  places,
}) => {
  const [ambassador, setAmbassador] = useState(null);

  useEffect(() => {
    if (ambasadorName) {
      const _ambasadorData = ambassadors.find(
        (e) => e.node.frontmatter.name === ambasadorName
      );

      if (_ambasadorData) {
        const {
          id,
          frontmatter: {
            name,
            avatarImage,
            place,
            department,
            whatsappLink,
            linkedinLink,
            email,
            phoneNumber,
            articleLink,
          },
        } = _ambasadorData.node;

        const cardPlace = places?.find((i) => i.node.frontmatter.name === place)
          ?.node?.frontmatter?.nameInCard;

        const articleSlug = otherPosts?.find(
          (i) => i.frontmatter.title === articleLink
        )?.fields?.slug;

        const data = {
          id: id,
          name: name,
          avatarImage: avatarImage,
          place: place,
          cardPlace: cardPlace,
          department: department,
          whatsappLink: whatsappLink,
          linkedinLink: linkedinLink,
          email: email,
          phoneNumber: phoneNumber,
          articleLink: articleSlug
        };

        setAmbassador(data);
      }
    }
  }, [ambassadors, places]);

  return (
    <>
      <Header
        bgImage={headerPostImage}
        minHeight={["500px", null, null, "814px", null]}
        bottomComponents={<TriangleBlogPost />}
        containerJustifyContent="flex-end"
        shadowAfter={true}
      >
        <Heading
          as="h1"
          color="white.100"
          mb={[3, 4, null, null, 3]}
          mt={[80, 0, 0, 0, 0]}
          fontSize={["32px", 5, 6, 7, null]}
          fontWeight="light"
          lineHeight="2"
          maxWidth={["100%", null, "1040px", null, null]}
          backgroundPosition="center center"
        >
          {title}
        </Heading>
        <Text
          color="white.100"
          fontSize="2"
          fontWeight="300"
          lineHeight="24px"
          mb={[4, 5, null, null, 6]}
          maxWidth={["100%", null, "630px", null, null]}
        >
          {description}
        </Text>
      </Header>
      <Wrapper mb="20px">
        <Container>
          <Article width={["100%", "100%", null, null, "91%"]}>
            {ContentComponent}
            {ambassador && (
              <Container
                width={["100%", null, null, null, null]}
                maxWidth="687px"
                flexDirection="column"
                px="0"
                py="0"
                mt="64px"
                mb="0"
                mx="auto"
              >
                {ambasadorTitle && (
                  <Heading
                    as="h3"
                    color="EYBlack.default"
                    fontSize={["24px", null, null, null, "40px"]}
                    lineHeight={["32px", null, null, null, "64px"]}
                    fontWeight="400"
                    mb={["16px", null, null, null, "24px"]}
                    textAlign={["left", null, null, null, "center"]}
                  >
                    {ambasadorTitle}
                  </Heading>
                )}
                {ambasadorDescription && (
                  <Text
                    color="#2E2E38"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    mb="32px"
                    width="100%"
                    textAlign={["left", null, null, null, "center"]}
                  >
                    {ambasadorDescription}
                  </Text>
                )}
                <Flex width={["100%", null, "388px", null, "420px"]} mx="auto">
                  <AmbassadorCard {...ambassador} width="100%" />
                </Flex>
              </Container>
            )}
            <ShareSocial url={location.href} />
          </Article>
        </Container>
      </Wrapper>
    </>
  );
};

export default BlogPostContent;
