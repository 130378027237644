import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Label = styled.label`
  font-size: 12px;
  font-weight: ${themeGet("fontWeights.light")};
  color: ${themeGet("colors.white.100")};
  text-transform: uppercase;
  border: none;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}

  max-width: 376px;

  &:active {
    color: ${themeGet("colors.EYOffBlack.default")};
  }

  &:hover {
    transition: background-color ${themeGet("times.transition")};
    border: none;
    outline: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

Label.displayName = `Label`;

Label.defaultProps = {
  mb: "5px",
};

Label.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { Label };
