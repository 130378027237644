import styled from "styled-components";

const SocialButton = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2E2E38;
  border-radius: 6px;
  transition: background-color 200ms;

  .icon {
    color: #FFE600;
    transition: color 200ms;

    svg {
      width: 100%;
    }
  }

  &:hover {
    background-color: #FFFFFF;

    .icon {
        color: #2E2E38;
    }
  }
`;

SocialButton.displayName = `SocialButton`;

SocialButton.defaultProps = {};

export { SocialButton };
