import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export const Checkbox = styled.label`
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    min-height: 32px;
    display: flex;
    align-items: center;
    // line-height: ${props => (props.special ? "130%" : "110%")};
    padding: ${props => (props.special ? "15px 0 20px 40px" : "0 0 0 40px")};
    margin-top: ${props => (props.special ? "20px" : 0)};
    border-top: ${props =>
      props.special ? `1px solid ${props.theme.colors.greyLight}` : "0"};
    border-bottom: ${props =>
      props.special ? `1px solid ${props.theme.colors.greyLight}` : 0};
    color: ${themeGet("colors.white.100")};
    transition: all .3s;

    &:hover input ~ span {
      background-color: grey;
    }

    & input:checked ~ span {
        border: 0;
    }

    & input:checked ~ span:after {
      display: block;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
      transition: all .3s;
      position: absolute;
      top: ${props => (props.special ? "14px" : "0")};
      left: 0;
      height: 24px;
      width: 24px;
      border: none;
      background-color: ${themeGet("colors.white.100")};

      &:after {
          content: "";
          position: absolute;
          display: none;
          left: 14px;
          top: -9px;
          width: 9px;
          height: 28px;
          border: solid black;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
      }
    }
`;
