import React from "react";
import Container from "../../style/Grid/Container";
import { Flex } from "../../style/Grid";
import { Image } from "../../style/Image";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import Icon from "../Icons";
import { SocialButton } from "../../style/SocialButton";
import arrowDark from "../../images/arrow2-dark.svg";
import arrowYellow from "../../images/arrow2-yellow.svg";
import getStyledLink from "../../style/Link/getStyledLink";
const Link = getStyledLink("a");
import getFormattedPhoneNumber from "../../utils/getFormattedPhoneNumber";

const AmbassadorCardComponent = ({
  className,
  style,
  avatarImage,
  name,
  place,
  cardPlace,
  department,
  whatsappLink,
  linkedinLink,
  email,
  phoneNumber,
  articleLink,
}) => {
  return (
    <Container style={style} className={className} flexDirection="column">
      <Container
        width={["100%", null, null, null, null]}
        flexDirection="column"
        px={["16px", null, null, null, "24px"]}
        py={["16px", null, null, null, "24px"]}
        flexGrow="1"
      >
        <Flex>
          {avatarImage?.childImageSharp && (
            <Image image={avatarImage} className="personImage" />
          )}
          <Container
            width={["100%", null, null, null, null]}
            flexDirection="column"
            px={
              avatarImage?.childImageSharp
                ? ["16px", null, null, null, "24px"]
                : "0"
            }
            pr={["0", null, null, null, "0"]}
          >
            <Container
              width={["100%", null, null, null, null]}
              flexDirection="column"
              px={["0px", null, null, null, null]}
              minHeight={[null, null, null, null, "88px"]}
            >
              {name && (
                <Heading
                  as="h3"
                  fontSize={["18px", null, null, null, "24px"]}
                  lineHeight={["24px", null, null, null, "30px"]}
                  color="EYBlack.default"
                  fontWeight="700"
                  mb="8px"
                >
                  {name}
                </Heading>
              )}
              {(place || cardPlace) && (
                <Text
                  width={["100%", null, null, null, null]}
                  color="EYBlack.default"
                  fontWeight="700"
                  fontSize="14px"
                  lineHeight="18px"
                  mb={department ? "4px" : "0"}
                >
                  {cardPlace ? cardPlace : place}
                </Text>
              )}
              {department && (
                <Text
                  width={["100%", null, null, null, null]}
                  color="EYBlack.default"
                  fontWeight="light"
                  fontSize="14px"
                  lineHeight="18px"
                >
                  {department}
                </Text>
              )}
            </Container>
            {(whatsappLink || linkedinLink) && (
              <Flex
                className="socialsButtonWrapper"
                mt={["6px", null, null, null, "12px"]}
              >
                {whatsappLink && (
                  <SocialButton
                    as="a"
                    title="Whatsapp"
                    href={whatsappLink}
                    className="socialButton"
                  >
                    <Icon
                      name="whatsapp2"
                      className="icon"
                      width="26px"
                      height="26px"
                    />
                  </SocialButton>
                )}
                {linkedinLink && (
                  <SocialButton
                    as="a"
                    title="Linkedin"
                    href={linkedinLink}
                    className="socialButton"
                  >
                    <Icon
                      name="linkedin2"
                      className="icon"
                      width="21px"
                      height="20px"
                    />
                  </SocialButton>
                )}
              </Flex>
            )}
          </Container>
        </Flex>
        {(email || phoneNumber) && (
          <Container
            width={["100%", null, null, null, null]}
            flexDirection="column"
            px={["0", null, null, null, null]}
            pt={["16px", null, null, null, null]}
            mt={["16px", null, null, null, null]}
            borderTop="1px solid #2E2E38"
          >
            <Text
              width={["100%", null, null, null, null]}
              color="EYBlack.default"
              fontWeight="light"
              fontSize="14px"
              lineHeight="24px"
              style={{ textTransform: "uppercase" }}
              mb="8px"
            >
              Spoj se se mnou
            </Text>
            {email && (
              <Link
                href={`mailto:${email}`}
                color="black.100"
                fontWeight="400"
                lineHeight={["24px", null, null, null, null]}
                fontSize={["16px", null, null, null, null]}
              >
                {email}
              </Link>
            )}
            {phoneNumber && (
              <Link
                href={`tel:${getFormattedPhoneNumber(phoneNumber)}`}
                color="black.100"
                fontWeight="400"
                lineHeight={["24px", null, null, null, null]}
                fontSize={["16px", null, null, null, null]}
              >
                {phoneNumber}
              </Link>
            )}
          </Container>
        )}
      </Container>
      {articleLink && (
        <Container
          width={["100%", null, null, null, null]}
          px={["16px", null, null, null, "24px"]}
          py={["13.5px", null, null, null, "24px"]}
          justifyContent="flex-end"
          position="relative"
        >
          <Link
            className="link"
            href={`/clanky${articleLink}`}
            color="black.100"
            fontWeight="400"
            lineHeight={["24px", null, null, null, null]}
            fontSize={["16px", null, null, null, null]}
            display="flex"
            alignItems="center"
          >
            <Text
              width={["100%", null, null, null, null]}
              color="EYBlack.default"
              fontWeight="700"
              fontSize="16px"
              lineHeight="20px"
            >
              Chci vědět víc
            </Text>
            <Flex width="24px" height="24px" ml="8px" position="relative">
              <Image
                src={arrowDark}
                position="absolute"
                left="0"
                top="0"
                className="arrowDark"
              />
              <Image
                src={arrowYellow}
                position="absolute"
                left="0"
                top="0"
                opacity="0"
                className="arrowYellow"
              />
            </Flex>
          </Link>
          <Container
            width="100%"
            height="100%"
            px="0"
            position="absolute"
            left="0"
            top="0"
          ></Container>
        </Container>
      )}
    </Container>
  );
};

export default AmbassadorCardComponent;
