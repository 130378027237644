import styled from "styled-components";
import { space, color, layout, typography, flexbox } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Input = styled.input`
  font-weight: ${themeGet("fontWeights.bold")};
  color: ${themeGet("colors.EYOffBlack.default")};
  border: none;
  border-radius: 0;
  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}

  width: 376px;

  @media screen and (max-width: ${themeGet("breakpoints.md")}) {
    width: 100%;
  }

  &:active {
    color: ${themeGet("colors.EYOffBlack.default")};
  }

  &:hover {
    transition: background-color ${themeGet("times.transition")};
    border: none;
    outline: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

Input.displayName = `Label`;

Input.defaultProps = {
  fontSize: "2",
  px: "16px",
  py: "9px",
  height: "48px",
  outline: "none",
};

Input.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
};

export { Input };
