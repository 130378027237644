import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../components/Layout";
import SEO from "../components/seo";

const URL = require("../constants").URL;

import { Article } from "../style/Article";
import { Flex, Wrapper } from "../style/Grid";

import BubbleNewsletter from "../components/BubbleNewsletter";
import TileButton from "../components/TileButton";
import BlogPostContent from "../components/BlogPostContent";
import usePageView from "../utils/usePageView";

const BlogPostTemplate = (props) => {
  usePageView(props.location.pathname, "Články a podcasty", "Detail článku");

  const person = {
    name: "Sandra Jirásková",
    position: "Recruitment manager",
    description: "Máš zájem o newsletter plný inspirace?",
    left: false,
  };

  const post = props.data.mdx;
  const siteTitle = props.data.site.siteMetadata.title;

  useEffect(() => {
    const addPodcastScript = () => {
      const script = document.createElement("script");
      script.src = document.querySelector(
        `div[id^="buzzsprout-player-"] ~ script`
      ).src;
      document.getElementsByTagName("head")[0].appendChild(script);
    };

    const addYoutubeWrapper = (youtubeWrapper) => {
      const youtubeIframe = youtubeWrapper.querySelector("iframe");

      const playButtonWrapper = document.createElement("div");
      playButtonWrapper.classList.add("play-btn-wrapper");

      const playButton = document.createElement("button");
      playButton.classList.add("play-btn");
      playButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20"><path fill="#2e2e38" d="M19 10L1 19V1z"/><path fill="none" stroke="#f6f6fa" stroke-miterlimit="50" stroke-opacity=".2" d="M19 10h0L1 19h0V1h0z"/></svg>`;

      playButton.addEventListener("click", () => {
        youtubeWrapper.removeChild(
          youtubeWrapper.querySelector(".play-btn-wrapper")
        );
        youtubeIframe.src +=
          (youtubeIframe.src.indexOf("?") > 0 ? "&" : "?") + "autoplay=1";
        youtubeWrapper.querySelector("iframe").classList.remove("black-white");
      });

      playButtonWrapper.appendChild(playButton);

      youtubeWrapper.querySelector("iframe").classList.add("black-white");

      youtubeWrapper.appendChild(playButtonWrapper);
    };

    if (
      document.body.contains(
        document.querySelector(`div[id^="buzzsprout-player-"] ~ script`)
      )
    ) {
      addPodcastScript();
    }

    const youtubeSelectorString = `.gatsby-resp-iframe-wrapper > iframe[src^="https://www.youtube"]`;

    if (document.body.contains(document.querySelector(youtubeSelectorString))) {
      Array.from(document.querySelectorAll(youtubeSelectorString)).map(
        (element) => {
          addYoutubeWrapper(element.parentNode);
        }
      );
    }
  }, []);

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.metaTitle || post.frontmatter.title}
        description={
          post.frontmatter.metaDescription ||
          post.frontmatter.description ||
          post.excerpt
        }
        ogimage={
          post.frontmatter.headerPostImage
            ? post.frontmatter.headerPostImage.publicURL
            : null
        }
      />
      <BlogPostContent
        post={post}
        headerPostImage={post.frontmatter.headerPostImage}
        location={props.location}
        ContentComponent={<MDXRenderer>{post.body}</MDXRenderer>}
        ambassadors={props.data.allAmbassador.edges}
        otherPosts={props?.data?.allBlogPosts?.nodes || []}
        places={props.data.allPlaces.edges}
      />

      <Wrapper as="section" bg="EYOffBlack.default">
        <Article
          mt={["64px", null, null, "120px", null]}
          mb={["64px", null, null, "120px", null]}
          width={["90%", "90%", null, null, "90%"]}
        >
          <BubbleNewsletter
            personImage={props.data.newsletterBubblePersonImage}
            {...person}
          />
        </Article>
      </Wrapper>
      <section>
        <Flex justifyContent="flex-end">
          <TileButton
            to={URL.blog}
            width="100%"
            tileColor="light"
            tileOrientation="right"
          >
            Chci si přečíst další článek
          </TileButton>
        </Flex>
      </section>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        metaTitle
        date(formatString: "MMMM DD, YYYY")
        description
        metaDescription
        headerPostImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        thumbnailPostImage {
          publicURL
        }
        ambasadorTitle
        ambasadorDescription
        ambasadorName
      }
    }
    allAmbassador: allMdx(
      filter: { frontmatter: { templateKey: { eq: "ambassador" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            avatarImage {
              childImageSharp {
                gatsbyImageData
              }
            }
            place
            isForeign
            department
            whatsappLink
            linkedinLink
            email
            phoneNumber
            articleLink
          }
        }
      }
    }
    allBlogPosts: allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true } }
      }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
    allPlaces: allMdx(
      filter: { frontmatter: { templateKey: { eq: "places" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            name
            nameInCard
            isForeign
            longitude
            latitude
          }
        }
      }
    }
    newsletterBubblePersonImage: file(
      relativePath: { eq: "recruiters/SandraJiraskova.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
      }
    }
  }
`;
